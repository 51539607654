/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Table } from '@az/starc-ui';
import { CMSImage } from '../../../CMSImage/CMSImage';
import { ContentStackProductChart } from '../../../../interface';
import { CMSLink } from '../../../CMSLink/CMSLink';
import styles from './HeaderCell.module.scss';

type Props = {
  product: NonNullable<ContentStackProductChart['product']>[number];
  readonly id: string;
};

const sizePerDevice = {
  mobile: {
    width: 130,
    height: 130,
  },
  tablet: {
    width: 160,
    height: 160,
  },
  desktop: {
    width: 180,
    height: 180,
  },
};

export const HeaderCell = ({ product, id }: Props) => (
  <Table.HeaderCell id={id} textAlign="center" className={styles.headerCell}>
    {product.image && product.link ? (
      <CMSLink to={product.link}>
        <CMSImage image={product.image} sizePerDevice={sizePerDevice} />
      </CMSLink>
    ) : product.image ? (
      <CMSImage image={product.image} sizePerDevice={sizePerDevice} />
    ) : null}
  </Table.HeaderCell>
);
