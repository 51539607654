/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { ContentStackButton, ContentStackButtonGrid } from '../../interface';
import { CMSButton } from '../CMSButton/CMSButton';
import { Grid } from '@/components/Grid';
import styles from './CMSButtonGrid.module.scss';

type Props = {
  content: ContentStackButtonGrid;
};

export const CMSButtonGrid = ({ content }: Props) => {
  return (
    <Grid
      container
      justify="center"
      className={cx(
        {
          [styles.background]: content.background_color,
        },
        styles.container
      )}
      spacing={2}
      data-testid="cms-button-grid-container"
    >
      {(content.button as unknown as ContentStackButton[] | undefined)?.map(
        (button: ContentStackButton, i) => (
          <Grid key={i} item>
            <CMSButton options={button} size="large" />
          </Grid>
        )
      )}
    </Grid>
  );
};
