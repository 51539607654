/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const rewardsConstants = {
  confirmation: 'confirmation',
  signin: 'signin',
  successMsg: 'successMsg',
  rewardsActivity: {
    Activity: 'Activity',
    Return: 'Return',
  },
  FAQ: 'FAQ',
  PROGRAM: 'PROGRAM',
  CREDIT: 'CREDIT',
  SUPPORT: 'SUPPORT',
  Back: 'Back',
  Submit: 'Submit',
  ORDERHISTORY: 'ORDERHISTORY',
  CITY: 'CITY',
  PHONE_NUMBER: 'PHONE_NUMBER',
  ZIP_CODE: 'ZIP_CODE',
  LAST_NAME: 'LAST_NAME',
  FIRST_NAME: 'FIRST_NAME',
  iconNotes: 'icon-notes.svg',
  iconNotesV2: 'notes_icon.svg',
  iconReward: 'group.svg',
  shoppingCartCreateAccount: 'shopping-cart-create-account.svg',
  profileIconCreateAccount: 'profile-icon-create-account.svg',
  carIconCreateAccount: 'car-icon-create-account.png',
  questionLabel: 'Choose a Security Question',
  adjustment: 'Adjustment',
};
