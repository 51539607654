/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
const REQUIRED_PROPS = ['theme', 'link'];

const isCMSButtonDefined = (button: unknown) =>
  !!button &&
  typeof button === 'object' &&
  REQUIRED_PROPS.every((property) => property in button && button[property as keyof typeof button]);

export { isCMSButtonDefined };
