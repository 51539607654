/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Legal } from '@/components/AZCustomComponent/Legal';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import { View, Table } from '@az/starc-ui';
import { ContentStackProductChart } from '../../../../interface';
import { CMSButton } from '../../../CMSButton/CMSButton';
import styles from './CTACell.module.scss';

type Props = {
  className: string;
  product: NonNullable<ContentStackProductChart['product']>[number];
};

export const CTACell = ({ className, product: { image, button, legal } }: Props) => (
  <Table.Cell className={className} textAlign="center">
    {legal && (
      <View className={styles.legalContainer}>
        <Legal overrideClassName={styles.legal} content={mapContentstackLegalValues(legal)} />
      </View>
    )}
    {button?.link?.title && (
      <CMSButton options={button} ariaLabel={`${image?.image_alt_text} ${button.link?.title}`} />
    )}
  </Table.Cell>
);
