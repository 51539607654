/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { Text, TextProps, View } from '@az/starc-ui';
import { ContentStackFeaturedContent } from '../../../interface';
import { CMSImage } from '../../CMSImage/CMSImage';
import styles from './CMSFeaturedContentItem.module.scss';
import { CMSLink } from '../../CMSLink/CMSLink';

type Props = NonNullable<ContentStackFeaturedContent['feature_content']>[number] & {
  textColor: TextProps['color'];
  direction: TextProps['align'];
};

export function CMSFeaturedContentItem({
  heading,
  description,
  image,
  textColor,
  direction,
  link,
}: Props) {
  const content = (
    <>
      {image?.image_url && (
        <View className={styles.imageContainer}>
          <CMSImage image={image} className={styles.image} />
        </View>
      )}
      {heading && (
        <Text
          size="100"
          weight="bold"
          color={textColor}
          lineHeight="140"
          className={styles.heading}
          align={direction}
        >
          {heading}
        </Text>
      )}
      {description && (
        <Text size="087" color={textColor} align={direction} className={styles.description}>
          {description}
        </Text>
      )}
    </>
  );

  return link ? (
    <CMSLink className={styles.container} to={link}>
      {content}
    </CMSLink>
  ) : (
    <View className={styles.container}>{content}</View>
  );
}
