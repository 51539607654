/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View } from '@az/starc-ui';
import cx from 'classnames';
import { ContentStackImageContentBackground } from '../../interface';
import styles from './CMSImageContentBackground.module.scss';
import { CMSImage } from '../CMSImage/CMSImage';
import { CMSButton } from '../CMSButton/CMSButton';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';

type Props = {
  content: ContentStackImageContentBackground;
};

export const CMSImageContentBackground = ({ content }: Props) => {
  const {
    background_color: backgroundColor,
    image,
    heading,
    description,
    button,
    image_direction: direction,
  } = content;
  const customStyles: React.CSSProperties = {
    '--background-color': backgroundColor?.hex,
  } as React.CSSProperties;

  return (
    <div
      style={backgroundColor ? customStyles : undefined}
      className={cx(styles.container, direction === 'Right' && styles.imageOnRightSide)}
    >
      {image && (
        <View className={styles.imgContainer}>
          <CMSImage image={image} />
          <View className={styles.imgBackground} />
        </View>
      )}
      <View className={styles.contentContainer}>
        <CMSHeadline className={styles.title} nested>
          {heading}
        </CMSHeadline>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description ?? '' }}
        />
        {button?.link?.title && (
          <View className={styles.buttonsContainer}>
            <CMSButton options={button} />
          </View>
        )}
      </View>
    </div>
  );
};
