/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Grid } from '@/components/Grid';
import styles from './CMSImageCategory.module.scss';
import azCommonStyles from '@/theme/globals.module.scss';
import { CMSLink } from '../CMSLink/CMSLink';
import { CMSImage } from '../CMSImage/CMSImage';
import type { ContentStackImageCategoryGrid, SizePerDevice } from '../../interface';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';

type Props = {
  content: ContentStackImageCategoryGrid;
};

const sizePerDevice: SizePerDevice = {
  desktop: {
    width: 150,
    height: 145,
  },
  tablet: {
    width: 106,
    height: 103,
  },
  mobile: {
    width: 200,
    height: 160,
  },
};

export function CMSImageCategory({ content }: Props) {
  const { headline, image_direction, image, tool_content } = content;

  return (
    <Grid container spacing={0}>
      <CMSHeadline>{headline}</CMSHeadline>
      <Grid
        container
        spacing={0}
        direction={image_direction === 'Right' ? 'row-reverse' : 'row'}
        className={styles.wrapper}
      >
        <div className={styles.imageContainer}>
          {image && <CMSImage image={image} className={styles.image} />}
        </div>
        <div className={styles.categoryContainer}>
          <Grid
            container
            spacing={0}
            component="ul"
            direction="column"
            className={styles.itemsContainer}
          >
            {tool_content?.map((tool, i) => {
              return (
                <Grid
                  key={i}
                  wrap="nowrap"
                  component="li"
                  container
                  spacing={0}
                  alignItems="center"
                  className={styles.item}
                >
                  <Grid item>
                    {tool.image && (
                      <div className={styles.itemImageContainer}>
                        <CMSImage
                          image={tool.image}
                          sizePerDevice={sizePerDevice}
                          className={styles.itemImage}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item>
                    {tool.link?.map((link, index) => (
                      <Grid key={index} item className={styles.link}>
                        <CMSLink to={link.href} className={azCommonStyles['az-body-2-link']}>
                          {link.title}
                        </CMSLink>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
