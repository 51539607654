/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import type { ContentStackRichTextEditor } from '../../interface/content-types';
import styles from './CMSRichText.module.scss';
import { Text, View, classNames } from '@az/starc-ui';
import { useRouter } from 'next/router';

type Props = {
  content: ContentStackRichTextEditor;
};

export const CMSRichText = ({ content }: Props) => {
  const { query } = useRouter();
  const isMXorBRCircularPage = query.pageName === 'folleto' || query.pageName === 'folheto';
  const description_background = content.description_background ?? 'None';

  const sectionClassName = classNames(
    description_background.match(/Padding/) && styles.padding,
    description_background.match(/Background[ ]?Color/) && styles.background,
    isMXorBRCircularPage && styles.circularPage
  );

  return (
    <>
      <View as="section" className={sectionClassName}>
        <Text
          className={cx(styles.richText, {
            [styles.centerAligned]: content.alignment === 'Center',
            [styles.fullWidth]: isMXorBRCircularPage,
          })}
          attributes={{ dangerouslySetInnerHTML: { __html: content.description } }}
        >
          {null}
        </Text>
      </View>
    </>
  );
};
