/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Label } from '@/features/i18n';
import { PHONENUMBER } from '../validatorRegex';

export function validateMobilePhoneNumber(value: string) {
  if (!PHONENUMBER.test(value)) {
    return <Label label="error_Common_Validation_PleaseEnterYourTenDigitMobileNumber" />;
  }
}
