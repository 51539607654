/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { AccordionGroup, View } from '@az/starc-ui';
import { useLabels } from '@/hooks/useLabels';
import type { ContentStackFaq } from '../../interface';
import { Grid } from '@/components/Grid';
import { FaqItem } from './FaqItem/FaqItem';
import styles from './CMSFaq.module.scss';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import Head from 'next/head';
import { useMemo } from 'react';
import { getFAQSchema } from '@/features/common/utils/getFAQSchema';

const labelMap = {
  frequentlyAskedQuestions: 'label_Rewards_FAQ_FrequentlyAskedQuestions',
};

type Props = {
  content: ContentStackFaq;
};

type WrapperProps = {
  children: React.ReactNode[];
  isGridTheme: boolean;
};

const Wrapper = ({ children, isGridTheme }: WrapperProps) => {
  if (isGridTheme) {
    return (
      <Grid container spacing={0} className={styles.gridContainer}>
        {children}
      </Grid>
    );
  }

  return <AccordionGroup variant="list">{children}</AccordionGroup>;
};

export const CMSFaq = ({ content }: Props) => {
  const { faq, theme, heading } = content;
  const { frequentlyAskedQuestions } = useLabels(labelMap);
  const faqHeading = heading || frequentlyAskedQuestions;
  const isGridTheme = theme === 'Grid View';

  const schema = useMemo(() => {
    if (!faq) return null;
    return JSON.stringify(getFAQSchema(faq));
  }, [faq]);

  if (!faq?.length) return null;

  return (
    <View>
      {schema && (
        <Head>
          <script
            key="cms-faq-schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: schema }}
          />
        </Head>
      )}
      <CMSHeadline>{faqHeading}</CMSHeadline>
      <Wrapper isGridTheme={isGridTheme}>
        {faq.map(({ question, answer }) => (
          <FaqItem key={question} question={question} answer={answer} isGridTheme={isGridTheme} />
        ))}
      </Wrapper>
    </View>
  );
};
