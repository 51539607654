/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View } from '@az/starc-ui';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import { ProductTable } from './ProductTable/ProductTable';
import { Legal } from '@/components/AZCustomComponent/Legal';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import styles from './CMSProductChart.module.scss';
import type { ContentStackProductChart } from '../../interface';
import { isProductChartTable } from '../../utils/typeGuards/isProductChartTable';

type Props = {
  content: ContentStackProductChart;
};

export const CMSProductChart = ({ content }: Props) => {
  const { heading, sub_heading, product_table, product, legal, table_color, title } = content;

  return (
    <View>
      {heading && (
        <View className={styles.headline}>
          <CMSHeadline underline={false} subHeading={sub_heading} nested>
            {heading}
          </CMSHeadline>
        </View>
      )}
      {isProductChartTable(product_table) && (
        <ProductTable
          tableData={product_table}
          productsToCompare={product}
          accentColor={table_color}
          tableId={`${heading ?? title}-product-chart`}
        />
      )}
      {legal && (
        <View className={styles.legal}>
          <Legal content={mapContentstackLegalValues(legal)} />
        </View>
      )}
    </View>
  );
};
