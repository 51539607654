/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackProductChartTable } from '../../interface/models';

export const isProductChartTable = (obj: unknown): obj is ContentStackProductChartTable =>
  !!obj &&
  typeof obj === 'object' &&
  'tableState' in obj &&
  typeof obj.tableState === 'object' &&
  !!obj.tableState &&
  'data' in obj.tableState;
