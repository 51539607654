/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View, Link } from '@az/starc-ui';
import type { ContentStackMenuList } from '../../interface';
import styles from './CMSMenuList.module.scss';

type Props = {
  content: ContentStackMenuList;
};

export const CMSMenuList = ({ content }: Props) => (
  <View className={styles.menuListContainer}>
    {content.menu_link.map((item, index) => (
      <Link key={index} href={item.href} className={styles.link}>
        {item.title}
      </Link>
    ))}
  </View>
);
