/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { Fragment } from 'react';
import { Grid } from '@/components/Grid';
import { Text, TextProps } from '@az/starc-ui';
import { ContentStackImage, ContentStackLink } from '../../../interface/content-types';
import { CMSLink } from '../../CMSLink/CMSLink';
import styles from './CategoryGridItem.module.scss';
import { useResponsiveImageData } from '../../../hooks/useResponsiveImageData';
import { Picture } from '@/components/Picture/Picture';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type Props = {
  image?: ContentStackImage;
  links?: ContentStackLink[];
};

const sizePerDevice = {
  desktop: {
    width: 'auto',
    height: 200,
  },
  tablet: {
    width: 'auto',
    height: 140,
  },
  mobile: {
    width: 'auto',
    height: 200,
  },
};

type GetLinkFontSizeOptions = {
  isMobile: boolean;
  i: number;
};

const getLinkFontSize = ({ isMobile, i }: GetLinkFontSizeOptions): TextProps['size'] => {
  if (i === 0) {
    return '175';
  }

  return isMobile ? '087' : '100';
};

export function CategoryGridItem({ image, links }: Props) {
  const imageData = useResponsiveImageData({ image, sizePerDevice });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Grid item className={styles.container}>
      <Picture
        mobile={imageData.mobile}
        tablet={imageData.tablet}
        desktop={imageData.desktop}
        altText={image?.image_alt_text ?? ''}
      />
      {links?.map(({ href, title }, i) => {
        const variant: TextProps['variant'] = i === 0 ? 'display-3' : 'text-link';
        const decoration: TextProps['decoration'] = i === 0 ? undefined : 'underline';
        const textCase: TextProps['textCase'] = i === 0 ? 'uppercase' : 'capitalize';
        const linkFontSize = getLinkFontSize({ isMobile, i });
        return (
          <Fragment key={i}>
            <Grid
              // @ts-expect-error Grid does not have typechecking for custom component props
              to={href}
              item
              component={CMSLink}
              className={i === 0 ? styles.firstLink : styles.link}
            >
              <Text
                variant={variant}
                decoration={decoration}
                textCase={textCase}
                size={linkFontSize}
              >
                {title}
              </Text>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
}
