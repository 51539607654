/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useCallback, useState } from 'react';
import { Grid } from '@/components/Grid';
import { CMSButton } from '../CMSButton/CMSButton';
import { ContentStackButton, ContentStackImageContent } from '../../interface';
import styles from './CMSMediaContent.module.scss';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import cx from 'classnames';
import { Button, FormControl, TextField } from '@az/starc-ui';
import { useSMSOptinMutation } from '@/features/sms-optin/api/postSMSOptin';
import { validateMobilePhoneNumber } from '@/utils/validator/validateMobilePhoneNumber';
import { validateRequired } from '@/utils/validator/validateRequired/validateRequired';
import { normalizePhone } from '@/utils/validator/normalizePhone';
import { useLabels } from '@/hooks/useLabels';
import { Label } from '@/features/i18n';

type Options = {
  button: ContentStackImageContent['button'];
  heading: ContentStackImageContent['heading'];
  heading_underline: ContentStackImageContent['heading_underline'];
  direction: ContentStackImageContent['image_direction'];
  description: ContentStackImageContent['description'];
  mobile_number?: ContentStackImageContent['mobile_number'];
  button_text?: ContentStackImageContent['button_text'];
};

type Props = {
  options: Options;
  mediaElement: React.ReactNode;
};

const inputMobile = {
  lblInputMobileNumber: 'optin_input_mobileNumber',
};

export function CMSMediaContent({ options, mediaElement }: Props) {
  const {
    heading,
    heading_underline,
    description,
    button,
    direction: csDirection,
    mobile_number,
    button_text,
  } = options;

  const { mutate: submitSMSOptin, isLoading, isError, isSuccess, reset } = useSMSOptinMutation();
  const direction = csDirection === 'Left' ? 'row-reverse' : 'row';
  const labels = useLabels(inputMobile);

  const [errorElement, setErrorElement] = useState<JSX.Element | null>();
  const [inputValue, setInputValue] = useState('');

  const onInputValueChange = useCallback(
    (value: string) => {
      reset();
      setErrorElement(null);
      setInputValue(normalizePhone(value));
    },
    [reset]
  );

  const onSubmit = useCallback(() => {
    const errorElement =
      validateRequired(inputValue, 'error_Common_Validation_MobileNumberRequired') ||
      validateMobilePhoneNumber(inputValue);

    if (!errorElement) {
      const data = {
        email: '',
        firstName: '',
        lastName: '',
        zip: '',
        phoneNumber: inputValue,
      };
      submitSMSOptin({ values: data });
    }
    setErrorElement(errorElement);
  }, [inputValue, submitSMSOptin]);

  return (
    <Grid container spacing={0} direction={direction} className={styles.container}>
      <Grid
        item
        className={cx(
          styles.contentContainer,
          csDirection === 'Left' ? styles.paddingLeft : styles.paddingRight
        )}
      >
        <Grid item>
          {heading && (
            <CMSHeadline underline={heading_underline} nested>
              {heading}
            </CMSHeadline>
          )}
          <Grid item className={styles.description}>
            <div dangerouslySetInnerHTML={{ __html: description ?? '' }} />
          </Grid>
          {mobile_number && (
            <FormControl hasError={!!errorElement || isError} hasSuccess={isSuccess}>
              <div className={styles.inputContainer}>
                <TextField
                  className={styles.mobileNumberInput}
                  id="sms-form-phoneNumber"
                  label={labels.lblInputMobileNumber}
                  value={inputValue}
                  required
                  onValueChange={onInputValueChange}
                />
                <FormControl.Error>
                  {errorElement || <Label label="label_genericError_TryAgain" />}
                </FormControl.Error>
                <FormControl.Success>
                  <Label label="label_Success" />
                </FormControl.Success>
              </div>
              <Button
                className={styles.mobileNumberButton}
                fullWidth
                onClick={onSubmit}
                disabled={isLoading}
              >
                {button_text}
              </Button>
            </FormControl>
          )}
          <Grid container direction="row">
            {(button as unknown as ContentStackButton[] | undefined)?.map(
              (button: ContentStackButton, i) => (
                <Grid key={i} item>
                  <CMSButton options={button} />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.imageContainer}>
        {mediaElement}
      </Grid>
    </Grid>
  );
}
