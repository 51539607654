/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { ContentStackProductGrid } from '../../interface/content-types';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import { Grid } from '@/components/Grid';
import { CMSProductCard } from './components/CMSProductCard';
import styles from './CMSProductTypeGrid.module.scss';

type Props = {
  content: ContentStackProductGrid;
  gridIndex: number;
};

const gridSpacingForLayout: Record<ContentStackProductGrid['layout'], number> = {
  Two: 6,
  Three: 4,
  Four: 3,
};

export const CMSProductTypeGrid = (props: Props) => {
  const { content, gridIndex } = props;
  return (
    <section>
      {content.headline ? (
        <CMSHeadline emphasize={content.headline_emphasized ? 'last' : undefined}>
          {content.headline}
        </CMSHeadline>
      ) : null}
      <Grid container justify="flex-start" data-testid={`product-grid-${gridIndex}`}>
        {content.product_card?.map((singleProduct) => (
          <Grid
            item
            sm={12}
            md={gridSpacingForLayout[content.layout]}
            key={singleProduct.product_name}
            className={styles.item}
          >
            <CMSProductCard
              product={singleProduct}
              detailsAlignment={content.product_details_alignment}
            />
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
