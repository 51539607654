/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Accordion, Text } from '@az/starc-ui';
import { Grid } from '@/components/Grid';
import cx from 'classnames';
import styles from './FaqItem.module.scss';

type Props = {
  question: string;
  answer: string;
  isGridTheme: boolean;
};

export const FaqItem = ({ isGridTheme, question, answer }: Props) => {
  if (isGridTheme) {
    return (
      <Grid item xs={12} lg={6} className={styles.gridItem}>
        <Text className={cx(styles.question)} variant="display-5" size="087">
          {question}
        </Text>
        <Text
          className={styles.answer}
          size="075"
          attributes={{
            dangerouslySetInnerHTML: { __html: answer },
          }}
        >
          {null}
        </Text>
      </Grid>
    );
  }

  return (
    <Accordion
      className={cx(styles.accordionItem, styles.question)}
      name={question}
      headerOptions={{
        label: question,
      }}
    >
      <Text
        className={styles.answer}
        size="075"
        attributes={{
          dangerouslySetInnerHTML: { __html: answer },
        }}
      >
        {null}
      </Text>
    </Accordion>
  );
};
