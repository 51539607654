/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { CMSButton } from '../../CMSButton/CMSButton';
import type { ContentStackProductGrid } from '../../../interface/content-types';
import styles from './CMSProductCard.module.scss';
import WarrantyBadge from '@/components/AZCustomComponent/WarrantyBadge';
import { CMSLink } from '../../CMSLink/CMSLink';
import { useLabel } from '@/hooks/useLabels';
import { CMSImage } from '../../CMSImage/CMSImage';
import { ViewProps } from '@az/starc-ui';

type CSProduct = Exclude<ContentStackProductGrid['product_card'], undefined>[0];
type Props = {
  product: CSProduct;
  detailsAlignment: ContentStackProductGrid['product_details_alignment'];
};

const warrantyText: Record<Exclude<CSProduct['warranty'], undefined>, string> = {
  One: '1',
  Two: '2',
  Three: '3',
  Four: '4',
  Five: '5',
};

const classNameForDetailAlignment: Record<
  Exclude<ContentStackProductGrid['product_details_alignment'], undefined>,
  string
> = {
  Left: styles.contentLeftAligned,
  Center: styles.contentCenterAligned,
};

export const CMSProductCard = (props: Props) => {
  const { product, detailsAlignment = 'Center' } = props;
  const yearWarrantyLabel = useLabel('label_MyWarrantiesDetails_YearWarranty');
  const buttonAlignment: ViewProps<'div'>['justify'] =
    detailsAlignment === 'Center' ? 'center' : 'start';
  const maybeLinkChildren = (
    <>
      {product.image && <CMSImage image={product.image} className={styles.image} />}
      <h4 className={styles.title}>{product.product_name}</h4>
      <p data-testid="product-card-description">{product.product_description}</p>
      {product.warranty ? (
        <WarrantyBadge
          warranty={`${warrantyText[product.warranty]}${yearWarrantyLabel}`}
          classes={{ root: styles.warranty }}
        />
      ) : null}
    </>
  );
  const commonProps = {
    className: styles.productCardLink,
  };

  return (
    <div
      className={`${styles.productCardContent} ${classNameForDetailAlignment[detailsAlignment]}`}
    >
      {product.link ? (
        <CMSLink {...commonProps} to={product.link}>
          {maybeLinkChildren}
        </CMSLink>
      ) : (
        <div {...commonProps}>{maybeLinkChildren}</div>
      )}
      {product.button?.theme ? (
        <CMSButton options={product.button} justify={buttonAlignment} />
      ) : null}
    </div>
  );
};
