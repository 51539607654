/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export function normalizePhone(value: string | undefined): string {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  switch (true) {
    case onlyNums.length >= 1 && onlyNums.length <= 3:
      return `(${onlyNums}`;
    case onlyNums.length >= 4 && onlyNums.length <= 6:
      return `(${onlyNums.slice(0, 3)})${' '}${onlyNums.slice(3)}`;
    case onlyNums.length >= 7 && onlyNums.length <= 10:
      return `(${onlyNums.slice(0, 3)})${' '}${onlyNums.slice(3, 6)}-${onlyNums.slice(6)}`;
    case onlyNums.length > 10:
      return `(${onlyNums.slice(0, 3)})${' '}${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    default:
      return '';
  }
}
