/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@/components/Modal';
import { ReduxState } from '../../types';
import { localStorage } from '@/utils/localStorage';
import dynamic from 'next/dynamic';
import { View } from '@az/starc-ui';
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import styles from './styles.module.scss';
import type { TabIndexes } from './interface';

const LazyModalBody = dynamic(
  () => import('./components/ModalBody/ModalBody').then((mod) => mod.ModalBody),
  {
    ssr: false,
    loading: () => (
      <View className={styles.modalSpinner}>
        <LoadingIndicator />
      </View>
    ),
  }
);

type Props = {
  isOpen: boolean;
  onClose(): void;
  activeTab: TabIndexes;
  setActiveTab: (a: TabIndexes) => void;
  id?: string;
};

export default function SignInModal({ isOpen, onClose, activeTab, setActiveTab, id }: Props) {
  const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false);
  const { userAuthenticated } = useSelector<ReduxState>(({ appData }) => appData) as any;
  const [showModalBody, setShowModalBody] = useState(isOpen);

  useEffect(() => {
    if (userAuthenticated) {
      onClose();
    }
  }, [userAuthenticated, onClose]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isOpen) {
      localStorage.setItem('signInModalSelectedTab', ['signIn', 'createAccount'][activeTab]);
      setShowModalBody(true);
    } else {
      timeout = setTimeout(() => setShowModalBody(false), 300);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, activeTab]);

  const handleChangeTab = (event: any, activeTabIndex: TabIndexes) => {
    setActiveTab(activeTabIndex);
  };

  const setDisableEscapeKey = useCallback(
    () => setDisableEscapeKeyDown(true),
    [setDisableEscapeKeyDown]
  );

  const setEnableEscapeKey = useCallback(
    () => setDisableEscapeKeyDown(false),
    [setDisableEscapeKeyDown]
  );

  const handleCloseModal = () => {
    setEnableEscapeKey();
    onClose();
  };

  return !userAuthenticated ? (
    <Modal
      id={id}
      isOpen={isOpen}
      handleClose={handleCloseModal}
      showCloseButton
      disableEscapeKeyDown={disableEscapeKeyDown}
      modalClasses={{ modalBody: styles.signInModalV1Body }}
      body={
        isOpen || showModalBody ? (
          <LazyModalBody
            activeTab={activeTab}
            onChangeTab={handleChangeTab}
            setEnableEscapeKey={setEnableEscapeKey}
            setDisableEscapeKey={setDisableEscapeKey}
          />
        ) : null
      }
    />
  ) : null;
}
