/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View, Table, Text } from '@az/starc-ui';
import { ContentStackProductChart, ContentStackProductChartTable } from '../../../interface';
import styles from './ProductTable.module.scss';
import { HeaderCell } from './HeaderCell/HeaderCell';
import { CTACell } from './CTACell/CTACell';
import { useRef } from 'react';
import useIsOverflow from '@/hooks/useIsOverflow';

type Props = {
  tableData: ContentStackProductChartTable;
  productsToCompare: ContentStackProductChart['product'];
  accentColor: ContentStackProductChart['table_color'];
  tableId: string;
};

const decodeHtmlChar = (str: string) => {
  if (typeof str !== 'string') return str;
  return str.replace(/&amp;/g, '&');
};

export const ProductTable = ({ tableData, productsToCompare, accentColor, tableId }: Props) => {
  const {
    tableState: { data },
  } = tableData;
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const isTableContainerOverflown = useIsOverflow(tableContainerRef, false);
  const customStyles = {
    '--table-background-accent': accentColor === 'Blue' ? '#dfe5ee' : '#E6E6E6',
  };

  return (
    <View className={styles.outerContainer}>
      <View className={styles.tableContainer} attributes={{ ref: tableContainerRef, style: {} }}>
        <Table className={styles.table} attributes={{ style: customStyles }}>
          <colgroup>
            <Table.Col />
            {productsToCompare?.map((_, index) => (
              <Table.Col key={index} />
            ))}
          </colgroup>
          <thead>
            <Table.Row attributes={{ style: { backgroundColor: 'transparent' } }}>
              <Table.HeaderCell
                id={`${tableId}-empty-cell`}
                className={styles.tableHeadCellSticky}
              />
              {productsToCompare?.map((product, index) => (
                <HeaderCell
                  key={`${tableId}-${product.link ?? ''}-header-cell${index}`}
                  product={product}
                  id={`${tableId}-${product.link ?? ''}-header-cell${index}`}
                />
              ))}
            </Table.Row>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <Table.Row key={index} className={styles.tableRow}>
                {Object.values(row).map((cell, cellIndex) => (
                  <Table.Cell
                    key={`${tableId}-${index}-${cellIndex}`}
                    textAlign="center"
                    className={styles.tableCell}
                  >
                    <Text size="087" weight={cellIndex === 0 ? 'bold' : 'regular'}>
                      {typeof cell === 'string' ? decodeHtmlChar(cell) : cell}
                    </Text>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
            <Table.Row key={data.length} className={styles.tableRow}>
              <Table.Cell className={styles.tableCell} />
              {productsToCompare?.map((product, index) => (
                <CTACell
                  key={`${tableId}-legal-${index}`}
                  className={styles.tableCell}
                  product={product}
                />
              ))}
            </Table.Row>
          </tbody>
        </Table>
      </View>
      {isTableContainerOverflown && <View className={styles.containerRightFrame} />}
    </View>
  );
};
